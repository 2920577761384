import Swiper from 'swiper';

jQuery(document).ready(function() {
    new Swiper('.jumbotron__swiper .swiper-container', {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        loop: false,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
        breakpoints: {
            768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 20
            },
            992: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 20
            }
        }
    });

    new Swiper('.clients__swiper .swiper-container', {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        loop: false,
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 10,
        breakpoints: {
            768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 20
            },
            992: {
                slidesPerView: 5,
                slidesPerGroup: 5,
                spaceBetween: 20
            }
        }
    });
});